@import "~react-image-gallery/styles/scss/image-gallery.scss";


@mixin animate($name) {
  animation-name: $name;
  animation-duration: 4s;
  animation-iteration-count: 2;
  animation-direction: normal;
  animation-timing-function: ease-in-out;
}





.circle {
    --d: 200px;
    width: var(--d);
    height: var(--d);
    background: #eaeaea;
    position: absolute;
    border-radius: 50%;
    box-shadow: inset 30px -20px 60px 0 #5b5b5b;
    margin-left: -48%;
      
    &::before {
      content: '';
      width: 0;
      height: 0;
      background: white;
      position: absolute;
      top: 30%;
      right: 30%;
      border-radius: 50%;
      box-shadow: 0 0 30px 10px white;
      
    }
    
    &::after {
      content: '';
      width: 240px;
      height: 100px;
      background: rgba(0, 0, 0, 0.7);
      position: absolute;
      bottom: -15%;
      right: 20%;
      border-radius: 50%;
      box-shadow: -2px 0 2px 0 rgba(0, 0, 0, 0.7);
      transform: rotateZ(10deg);
      transform-origin: bottom right;
      z-index: -1;
    }
    
    @include animate(move);
  }
  
  
  
  
  
 .bg {
   position: relative;
    background: #e2e2e2;
    background-image: linear-gradient(to bottom, #8a8a8a 25%, #9E9E9E 5%, #ffffff);
    display: flex;
    align-items: center;
    justify-content: center;
    height:500px;
    z-index: 2;
  
  }
  




  @keyframes roll {
    0% {
      transform: rotate(20deg);
      margin-left: -48%;
    }
    100% {
      transform: rotate(340deg);
      margin-left: 48%;
    }
  }


  @keyframes move {
    0% {
      transform: translateX(0);
      margin-left: -48%;
    }
    100% {
      transform: translateX(0);
      margin-left: 48%;
    }
  }




  @keyframes shadow {
    0% {
      transform: translateX(0);
      margin-left: -48%;
    }
    100% {
      transform: translateX(400px);
      margin-left: 48%;
    }
  }



  .typewriter div {
    color: #fff;
    font-family: monospace;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .10em solid rgb(241, 239, 234); /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .10em; /* Adjust as needed */
    animation: 
      typing 2.5s steps(30, end),
      blink-caret .5s step-end infinite;
  }
  


  


  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: rgb(248, 248, 246) }
  }


//=-=============================================TYPE=========================
  //paragraph divided typing effect


  .type p{
    color: rgb(242, 248, 242); 
  
    white-space: nowrap;
    overflow: hidden;
    width: 25em;             //set the width if overflow occurs
    animation: type 4s steps(60, end); 
  }
  
  .type p:nth-child(2){
    animation: type2 3s steps(60, end);
  }
  
 .type p a{
    color: rgb(248, 250, 248);
    text-decoration: none;
  }
  
  .caret{
    animation: blink 1s infinite;
  }
  
  @keyframes type{ 
    from { width: 0; } 
  } 
  
  @keyframes type2{
    0%{width: 0;}
    50%{width: 0;}
    100%{ width: 200px; } 
  } 
  
  @keyframes blink{
    to{opacity: .0;}
  }
  
  ::selection{
    background: black;
  }

  ///==========CAROUSEL STYLE=========================

  .carousel-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.carousel-wrapper {
    display: flex;
    width: 100%;
    position: relative;
}

.carousel-content-wrapper {
    overflow: hidden;
   width: 100%;
  height: auto;
  
}

.carousel-content {
    display: flex;
    transition: all 250ms linear;
    -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
    scrollbar-width: none;  /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar {
    display: none;
}

.carousel-content > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
}



/* ... */
.left-arrow, .right-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 48px;
  border-radius: 4px;
 // background-color: rgb(75, 73, 73);
  border: 1px solid #ddd;
}

.left-arrow {
  left: -20px;
}

.right-arrow {
  right: -20px;
}
/* ... */

/* PAGE CAROUSEL NAV */

.page-carousel-left-arrow{
  position: absolute;
  display: flex;
  z-index: 1;
  top: 70%;
  right: 50%;;
  transform: translateY(-50%);

}

.card{

  box-shadow: -1rem 0 3rem #000;
 
  transition: 0.4s ease-in-out;
}

.card:hover {
 
 transform: translateY(-20px);

}



//=============BAR SENDING MESSAGE INDICATOR====================
.bar {
  position: absolute;
  top: 5px;
  left: 10px;
  height: 5px;
  width: 150px;
}



.emptybar {
  background-color: #2e3033;
  width: 100%;
  height: 100%;
}

.filledbar {
  position: absolute;
  top: 0px;
  z-index: 3;
  height: 100%;
  background: rgb(0,154,217);
  background: linear-gradient(90deg, rgba(0,154,217,1) 0%, rgba(217,147,0,1) 65%, rgba(255,186,0,1) 100%);
  //transition: 0.6s ease-out;
  
}

//==========IMAGE GALLERY WRAPPER FOR XS VIEW PORTS=============///@at-root




@media only screen and (max-width: 400px) {
  .gallery-wrapper {
    width:300px 
  }
}